@import '../node_modules/sal.js/dist/sal.css';

@import "./defaults.less";

html,
body {
    background-color: @site-background;
    font-family: @secondaryFont;
}

.ant-layout {
    padding-top: @header-size;
    background-color: @page-background;
    min-height: 100vh;

    @media only screen and (max-width: @header-mobile-width) {
        padding-top: @header-size-mobile;
    }
}

.ant-layout-header {
    padding: 0 calc((100% - @header-desktop-width) / 2);
    position: fixed;
    display: flex;
    flex-direction: row;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: @header-size;
    line-height: @header-size;
    background: transparent;
    font-family: @primaryFont;

    &:before {
        background: @header-background;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    &:after {
        width: 100%;
        height: 40px;
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        box-shadow: 0 0px 10px 7px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
    }

    >*:not(.menu-burger-open) {
        z-index: 3;
    }

    &.header-burger-open .menu {
        z-index: 0;
    }

    .logo {
        padding: 0 10px;
        display: block;
        width: @logo-width;
        height: auto;

        a {
            img {
                width: auto;
                height: 50px;
            }
        }

        @media only screen and (max-width: @header-mobile-width) {
            width: 55%;
            max-width: 400px;
        }
    }

    .menu-languages {
        line-height: @header-line-height;
        background-color: transparent;
        color: @header-menu-color;
        border-bottom: none;
        display: flex;

        .ant-menu-submenu-title:hover {
            color: @header-menu-color-hover  !important;
        }

        li {
            margin-bottom: 20px !important;
            display: inline;

            &:hover {
                border-color: @header-menu-color-hover  !important;
            }
        }
    }

    .menu {
        display: flex;
        width: 100%;
        line-height: @header-line-height;

        @media only screen and (max-width: @header-mobile-width) {
            display: none;
        }

        ul {
            background-color: transparent;
            margin-left: auto;
            border-bottom: none;
            border-right: none;

            li {
                display: inline;
                border: 0 !important;
                background-color: transparent !important;

                &:after {
                    content: unset;
                }

                @media screen and (max-width: @header-mobile-width) {
                    display: block;
                    padding-left: 0 !important;
                }

                &:hover {
                    a {
                        color: @header-menu-color-hover;
                    }

                    border-color: @header-menu-color-hover  !important;
                }

                a {
                    color: @header-menu-color;
                    font-size: 1em;
                }

                &.ant-menu-submenu-horizontal::after {
                    content: '>';
                    color: rgba(0, 0, 0, 0.65);
                    transform: rotate(90deg);
                    position: absolute;
                    right: -20px;
                    top: 2px;
                    font-weight: 600;
                }
            }
        }

        &-languages {
            margin-left: 0 !important;

            @media screen and (max-width: @header-mobile-width) {
                margin-right: 85px;
                margin-left: auto !important;
            }
        }
    }

    .menu-burger-button {
        position: absolute;
        width: auto;
        height: auto;
        top: 18px;
        right: calc(@header-size / 4);

        @media only screen and (min-width: @header-mobile-width) {
            display: none;
        }

        .burger {
            height: calc(@header-size-mobile / 2);
        }
    }

    .menu-burger {
        @media only screen and (min-width: @header-mobile-width) {
            display: none;
        }
    }
}

.ant-menu-sub,
.ant-menu-submenu-popup {
    border-radius: 2px !important;
}

.header-burger-open {
    .menu {
        display: block;
        position: fixed;
        width: 100%;
        height: calc(100% - @header-size);
        top: @header-size;
        left: 0;
        right: 0;
        z-index: 9998;
        background-color: @mobile-menu-background;
        padding: @mobile-menu-padding-vertical @mobile-menu-padding-horizontal;
        overflow: auto;
        margin-top: 0;

        @media only screen and (max-width: @header-mobile-width) {
            height: calc(100% - @header-size-mobile);
            top: @header-size-mobile;
        }
    }
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
    background-color: @header-menu-color;
}


.ant-layout-content {
    background-color: @page-background;
    z-index: 2;

    a {
        color: @primary-link-color;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
            color: @primary-link-hover-color;
            text-decoration: underline;
        }
    }
}

.ant-layout-footer {
    z-index: 3;
    width: 100%;
    position: relative;
    padding: 0 0 20px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2),
        0 1px 1px 0 rgba(0, 0, 0, .14),
        0 2px 1px -1px rgba(0, 0, 0, .12);
    background: @footer-background;

    @media only screen and (max-width: @header-mobile-width) {
        text-align: center;
        padding: 20px 0 10px;
    }

    &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        top: -40px;
        left: 0;
        border-style: solid;
        border-width: 40px 0 0 calc(100vw - 20px);
        border-color: transparent transparent transparent @footer-background;

        @media only screen and (max-width: @header-mobile-width) {
            top: -20px;
            border-width: 20px 0 0 calc(100vw - 20px);
        }
    }

    h1 {

        font-family: @primaryFont;
        color: @footer-color;
        font-size: 25px;
        padding: 0 10px;


        @media only screen and (min-width: 1200px) {
            margin: 0 calc((100% - 1200px) / 2);
        }

        @media only screen and (max-width: @header-mobile-width) {
            width: 100%;
            margin: 0 auto;
            font-size: 20px;
        }
    }

    .line {
        height: 20px;
        opacity: .5;
        border: 0;
        border-radius: 50%;
        border-bottom: 2px solid @primary-color;
        width: 100%;
    }


    .links-bar,
    .copyright {
        padding: 0 10px;
        display: inline-block;
        width: 50%;
        font-size: .875em;

        a {
            color: @footer-color;
            text-decoration: underline;
        }

        @media screen and (min-width: 1200px) {
            width: calc(50% - calc((100% - 1200px) / 2));
        }

        @media only screen and (max-width: @header-mobile-width) {
            display: block;
            width: 100%;
            margin: 20px auto !important;
            font-size: 12px;
            text-align: center;
        }
    }

    .links-bar {
        margin: 20px auto;

        @media screen and (min-width: 1200px) {
            margin-left: calc((100% - 1200px) / 2);
        }
    }

    .copyright {
        color: @footer-color;
        text-align: right;

        @media screen and (min-width: 1200px) {
            margin-right: calc((100% - 1200px) / 2);
        }
    }

    .cellphone {
        color: @footer-color;
        text-align: left;

        @media screen and (min-width: 1200px) {
            margin-right: calc((100% - 1200px) / 2);
        }
    }

    .copyright,
    .cellphone {
        @media screen and (max-width: @header-mobile-width) {
            text-align: center;
        }
    }

}
@primary-color: #5C8012;@layout-header-background: #ffffff;@font-size-base: 16px;@border-radius-base: 20px;