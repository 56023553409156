@chrome-header-size-mobile: 56px;

@site-background: #ffffff;

@primary-color:#1f2900;

@primary-title-color: #ffffff;
@primary-text-color: rgba(255, 255, 255, 0.9);
@secondary-text-color: rgba(255, 255, 255, 0.85);

@primary-link-color: rgba(255, 255, 255, 0.9);
@primary-link-hover-color: #6F8C2A;

@page-shadow-size: 25px;
@page-shadow-color: lighten(@primary-color, 50%);
@page-background: #3a4126;

@header-background: #242918;
@header-menu-color: #ffffff;
@header-menu-color-hover: #bdbdbd;
@header-size: 64px;
@header-line-height-fix: 0;
@header-line-height: 64px;
@header-size-mobile: 64px;
@header-mobile-width: 768px;
@header-latop-width: 991px;
@header-desktop-width: 1200px;

@mobile-menu-padding-vertical: 25px;
@mobile-menu-padding-horizontal: 25px;
@mobile-menu-background: #242918;

@logo-width: 100%;

@footer-background: #181a15;
@footer-size: 380px;
@footer-color: #FFFFFF;

@actions-item-border-color: #5C8012;
@actions-item-border-width: 0;
@actions-item-border-radius: 60px;
@actions-item-background: #5C8012;
@actions-item-text-color: #ffffff;
@actions-item-text-color-hover: #000000;
@actions-item-link-color: #ffffff;
@actions-item-link-color-hover: #00A0C6;
@actions-item-background-hover: #77a813;

@listing-service-list-service-bg: rgba(0,0,0,0.1);

@listing-plans-bg: #242918;
@listing-plans--plan-bg: #595B34;
@listing-plans--plan-hover-bg: #5d5f37;

@listing-comparison-plans-bg: #3A4126;
@listing-comparison-plans-border: rgba(255, 255, 255, .2);
@listing-comparison-checkmark: #ffffff;

@contact-form-bg: #242918;
@contact-form-form-bg: #595B34;
@contact-form-form-color: #ffffff;

@banner-secondary-darken-bg-color: rgba(0,0,0,0.35);
@banner-secondary-text-shadow: rgba(0,0,0,0.25);

@banner-sub-banner-gradient: linear-gradient(90deg, rgba(9, 164, 200, 0.2) 0%, #09a4c8 100%);
@banner-sub-banner-bg: #ffffff;
@banner-sub-banner-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
@banner-sub-banner-color: rgba(0, 0, 0, 0.8);

@primaryFont: 'Montserrat';
@secondaryFont: 'Roboto';

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/montserrat-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/montserrat-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/roboto-v20-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/montserrat-v15-latin-300.woff') format('woff'), /* Modern Browsers */
         url('/fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/roboto-v20-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}